import React from 'react'
import {Grid} from '@mui/material'
const Footer = ()=>{
    return (<>
    <div>
        <Grid container spacing={2}>
            <Grid item xs={6}><a href="tel:+919959067032">Contact</a></Grid>
            <Grid item xs={6}><a href='https://www.instagram.com/happyvivaah26/'>Instagram</a></Grid>
        </Grid>
    </div>
    </>)
}

export default Footer